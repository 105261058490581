import React from "react";

const SvgLogo = props => (
  <svg width={40} height={29} {...props}>
    <defs>
      <path id="logo_svg__a" d="M0 .133h18.994v18.274H0z" />
      <path id="logo_svg__c" d="M.044.08h23.999v10.681H.044z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M34.712 23.369c-.706.699-1.965 1.492-4.031 1.563-.956.032-2.072.036-2.583.036H16.994l7.964-7.742a77.896 77.896 0 0 1 1.9-1.785c1.56-1.405 2.965-1.688 3.958-1.679a5.653 5.653 0 0 1 3.982 1.677c2.164 2.214 2.113 5.751-.086 7.93m2.66-10.45a9.137 9.137 0 0 0-6.543-2.76c-2.222 0-4.136.767-5.806 2.128-.729.593-1.492 1.304-2.454 2.238L8.254 28.428c.726.103 1.723.134 2.613.14.833.005 16.733.003 17.398.003 1.34 0 2.212-.001 3.148-.07 2.148-.157 4.177-.945 5.817-2.557a9.208 9.208 0 0 0 .142-13.025"
        fill="#00A4FF"
      />
      <g transform="translate(0 10.159)">
        <mask id="logo_svg__b" fill="#fff">
          <use xlinkHref="#logo_svg__a" />
        </mask>
        <path
          d="M14.982 2.03C13.322.81 11.464.13 9.358.133a9.414 9.414 0 0 0-6.676 2.76c-3.627 3.638-3.571 9.45.145 13.03 1.491 1.438 3.299 2.22 5.226 2.484l3.636-3.462a79.113 79.113 0 0 1-2.18-.035c-2.109-.07-3.393-.864-4.114-1.563-2.244-2.18-2.296-5.718-.088-7.932a5.826 5.826 0 0 1 4.064-1.678c.995-.004 2.345.28 3.87 1.52.752.611 2.448 2.075 3.12 2.672l2.633-2.521c-1.206-1.07-2.966-2.614-4.012-3.378"
          fill="#00C6DA"
          mask="url(#logo_svg__b)"
        />
      </g>
      <g transform="translate(7.619)">
        <mask id="logo_svg__d" fill="#fff">
          <use xlinkHref="#logo_svg__c" />
        </mask>
        <path
          d="M24.03 8.401C22.322 3.554 17.725.081 12.318.081 6.115.08.975 4.664.044 10.635a9.207 9.207 0 0 1 1.535-.129 8.832 8.832 0 0 1 2.096.247l.039.007c.84-4.032 4.386-7.027 8.604-7.027 3.512 0 6.593 2.13 7.982 5.147.022.048.06.063.097.053 1.045-.289 2.298-.457 3.507-.367.118.009.164-.058.126-.166"
          fill="#006EFF"
          mask="url(#logo_svg__d)"
        />
      </g>
    </g>
  </svg>
);

export default SvgLogo;
