import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, FormGroupList, StaticFormGroup, notification, Dialog } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
import queryString from 'query-string';
const { extractionCode, promotionUpload } = iceStarkData.store.get('commonAction')?.tagbatchConfigs;
import Logo from '../../../../icons/Logo';
import './index.scss';

import ExtractionCodeTableForm from './table.js';

import Upload from "rc-upload";
import url from '@/api/urls';
import { apiBaseConfig } from '@/redux/utils';
let fileName = '';



const required = (val) => {
    if (!val) {
        return '验证码不能为空';
    }
    return null;
};
const required2 = (val) => {
    if (!val) {
        return '提取码不能为空';
    }
    return null;
};



@connect(
    state => ({
        extractionList: state.tagbatchConfigs.extractionList,
        form: state.form.ExtractionCodeForm || {},
    }),
    {
        extractionCode: extractionCode.REQUEST,
        promotionUpload: promotionUpload.REQUEST,
    }
)
class ExtractionCodePackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isdisable: false,
            fileName: "",
            dialogModel: { show: false, data: {}, type: false },
        };
        this.extractionUrl = '';

        this.uploaderProps = {
            accept: "application/vnd.ms-excel,text/csv,.csv",
            // action: url.data.upload.upload('PRIVATE'),
            ...apiBaseConfig,
            multiple: true,
            beforeUpload(file) {
                fileName = file.name || '';
                const isJPG = file.type && ["application/vnd.ms-excel", "text/csv"].includes(file.type);
                if (!isJPG) {
                    notification.warning("请确认文件格式");
                }
                const isLt2M = file.size / 1024 / 1024 < 5;
                if (!isLt2M) {
                    notification.warning("文件不能大于5M");
                }
                return isJPG && isLt2M;
            },
            customRequest: file => {
                const { form, initialize } = this.props;
                const obj = {
                    ...form.values,
                    // prizeCoupon: {
                    //     ...prizeCoupon,
                    //     couponCode: {
                    //         ...prizeCoupon.couponCode,
                    uploadFile: file,
                    // }
                    // }
                };
                initialize(obj);
                this.setState({ fileName });
            },
            onStart: file => {
                // this.refs.inner.abort(file);
            },
            // onSuccess: file => {
            //     const { form = { ExtractionCodeForm: {} }, initialize } = this.props;
            //     const { ExtractionCodeForm = { values: {} } } = form;
            //     const obj = {
            //         ...ExtractionCodeForm.values,
            //         // prizeCoupon: {
            //         //     ...prizeCoupon,
            //         //     couponCode: {
            //         //         ...prizeCoupon.couponCode,
            //         fileUrl: file.filePath
            //         // }
            //         // }
            //     };
            //     initialize(obj);
            //     this.setState({ fileName });
            // },
            onProgress(step, file) {
            },
            onError(err) {
            }
        };
    }
    componentDidMount() {
        const query = queryString.parse(window.location.search);
        this.extractionUrl = (query.extraction_url || '').replace('/api-gateway', '');
    }

    componentDidUpdate(preProps) {
        if (this.props.extractionList !== preProps.extractionList) {
            window.localStorage.setItem('EXTRACTIONCODE', JSON.stringify({ [this.extractionCode]: this.extractionUrl + this.extractionCode }));

            //跳转到会员信息下载
            if (this.extractionUrl.includes('/member-center/export/v1/download-info/')) {
                window.location.href = window.location.origin + window.location.pathname + `?extractionCode=${this.extractionCode}#/memberFile`
            }
            // //跳转到信息上传
            // else if (this.extractionUrl.includes('/promotion/upload/')) {
            //     window.location.href = window.location.origin + window.location.pathname + `?extractionCode=${this.extractionCode}#/memberFile`
            // }
            else {
                window.location.href = window.location.origin + window.location.pathname + `?extractionCode=${this.extractionCode}#/extractionCodeFile`
            }
        }
    }


    // 提交
    handleSubmit = (data) => {
        if (this.extractionUrl.lastIndexOf('=') == -1) {
            this.extractionUrl += '=';
        }
        this.extractionCode = data.extractionCode;
        this.props.extractionCode(this.extractionUrl + data.extractionCode);
    }



    //上传
    handleSubmit2 = (data) => {
        if (data.uploadFile) {

            if (!this.state.isdisable) {
                this.setState({ isdisable: true })
                this.props.promotionUpload({
                    data, callback: (res) => {
                        if (res.count) {
                            this.setState({ dialogModel: { show: true, data: res, type: 2 } })
                        }
                        else if (res.content) {
                            this.setState({ dialogModel: { show: true, data: res, type: 1 } })
                        }
                        this.setState({ isdisable: false })
                    }
                });
            }
        }
        else {
            notification.warning("请选择文件");
        }
    }
    upload_seehistory = (data) => {
        window.location.href = window.location.origin + window.location.pathname + `?extractionCode=promotionUpload#/promotionUpload`
    }
    handleCancel = () => {
        this.setState({ dialogModel: { ...this.state.dialogModel, show: false } })
        setTimeout(() => {
            this.setState({ dialogModel: { ...this.state.dialogModel, data: {}, type: false } })
        }, 200);
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className="invitation-code">
                <div className="title"><Logo />
                    {this.extractionUrl.includes('/member-center/export/v1/download-info/') ? '优码平台会员信息提取' : this.extractionUrl.includes('/promotion/upload') ? '优码平台批次信息上传' : '优码平台码包提取'}

                </div>

                {this.extractionUrl.includes('/promotion/upload') &&
                    <div>
                        <div className="block" style={{ position: 'relative' }}>
                            <div className="form-body" >
                                <FormGroupList>
                                    <StaticFormGroup
                                        name={"uploadFile.name"}
                                        label="文件"

                                    >
                                        <Upload {...this.uploaderProps}>
                                            <Button>上传文件</Button>
                                        </Upload>
                                        <span style={{ color: '#888888', marginLeft: 5, }}>
                                            {this.state.fileName || ""}
                                        </span>
                                        <a
                                            href={`${url.baseCdnUrl}/templates/批次导入示例文件.csv`}
                                            style={{ marginLeft: "10px", color: '#006eff' }}
                                        >
                                            下载导入模板
                                    </a>

                                    </StaticFormGroup>
                                    <FormGroupField
                                        // className="form-input-invitationCode"
                                        name="data"
                                        label="请输入验证码"
                                        component={Input}
                                        validate={[required]}
                                    />




                                </FormGroupList>
                                <Button disabled={this.state.isdisable} style={{ marginTop: 50, marginLeft: 10 }} onClick={handleSubmit(this.handleSubmit2)}>验证上传</Button>

                            </div>
                            <div style={{ position: 'absolute', right: 20, bottom: 20, fontSize: 12, cursor: 'pointer', color: '#006EFF' }} onClick={() => { this.upload_seehistory() }}>查看历史</div>
                        </div>
                        <ExtractionCodeTableForm></ExtractionCodeTableForm>
                    </div>
                }




                {!this.extractionUrl.includes('/promotion/upload') &&
                    <div className="block">
                        <div className="form-body">
                            <FormGroupList>
                                <FormGroupField
                                    className="form-input-invitationCode"
                                    name="extractionCode"
                                    label="请输入提取码"
                                    component={Input}
                                    validate={[required2]}
                                />
                                <Button onClick={handleSubmit(this.handleSubmit)}>提取文件</Button>
                            </FormGroupList>
                        </div>
                    </div>
                }

                <Dialog
                    title={this.state.dialogModel.type == 2 ? "上传成功" : "验证失败"}
                    className='promotionUploadModel'
                    visible={this.state.dialogModel.show}
                    onCancel={this.handleCancel}
                    onOk={this.handleCancel}
                    style={{ width: '500px' }}

                >
                    {this.state.dialogModel.type == 1 &&
                        <div>
                            <p style={{ fontSize: 12 }}>失败总条数：{this.state.dialogModel.data.content.length}</p>
                            <div className='box' style={{ display: 'inline-block' }}>
                                <div className='box-head' >
                                    <div>行数</div>
                                    <div>生产批次号</div>
                                    <div>失败原因</div>
                                </div>

                                {this.state.dialogModel.data.content.map((item, index) =>
                                    <div className='box-body' key={index}>
                                        <div>{item.index}</div>
                                        <div>{item.batchNo}</div>
                                        <div>{item.emsg}</div>

                                    </div>
                                )}
                            </div>
                        </div>}

                    {this.state.dialogModel.type == 2 &&
                        <div>
                            <p style={{ fontSize: 12 }} style={{ textAlign: 'center' }}>上传成功</p>
                            <p style={{ fontSize: 12 }} style={{ textAlign: 'center' }}>本次上传批次号数量：{this.state.dialogModel.data.count}</p>

                        </div>}

                </Dialog>


            </div >



        )
    }
}


export default reduxForm({
    form: 'ExtractionCodeForm',
    initialValues: {

    }
})(ExtractionCodePackage)